import React, { useState, useEffect } from "react";
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';




const Projects = props => {

    const [media, setMedia] = useState([])


    useEffect(function () {
        fetch("/projects").then(res => {
            if (res.ok) {

                return res.json()
            }
        })
            .then(jsonRes => setMedia(jsonRes))
    }, [])

    const photo = []
    const video = []


    const separateMediaTypes = () => {
        media.forEach(file => {
            if (file.includes(".jpeg") || file.includes(".jpg")) { photo.push(file) }
            else { video.push(file) }
        })
    }

    if (media.length !== 0) { separateMediaTypes() }


    return (<div className="projects" id="PROJECTS">
        <h1>PROJECTS</h1>
        <div className="photos">

            {photo.map(file => {

                if (file.includes(".jpeg")||file.includes(".jpg")) {

                    return <img key={file} src={"/uploads/" + file} 
                    alt={file + " not found "} />
                }
            })}

        </div>


        <div >
            {video.map(file => {
                if (file.includes(".mp4")){
                    return (
                        <div className="video" key={file}>
                            <Player>
                                <source src={"/uploads/" + file} />
    
                                <ControlBar>
                                    <ReplayControl seconds={10} order={1.1} />
                                    <ForwardControl seconds={30} order={1.2} />
                                    <CurrentTimeDisplay order={4.1} />
                                    <TimeDivider order={4.2} />
                                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                    <VolumeMenuButton disabled />
                                </ControlBar>
                            </Player>
                           
                        </div>
                    )
                }

                

            })}
        </div>

    </div>)
}


export default Projects